import React from "react"

import * as sectionHeaderStyles from "../styles/components/section-header.module.scss"

const SectionHeader = (props: any) => {
  const topTitle = props.topTitle
  const middleTitle = props.middleTitle
  const bottomTitle = props.bottomTitle

  return (
    <div className={sectionHeaderStyles.horizontalContainer}>
      {props.children}
      <div className={sectionHeaderStyles.titlesContainer}>
        <h6>{topTitle}</h6>
        <h2>{middleTitle}</h2>
        <h6>{bottomTitle}</h6>
      </div>
    </div>
  )
}

export default SectionHeader
