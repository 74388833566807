import React from "react"
import { IGatsbyImageData } from "../../node_modules/gatsby-plugin-image"
import styled from "../../node_modules/@emotion/styled"
import { PrimaryButton, PageTitle } from "../styles/components/shared-styles"
import { GatsbyImage } from "gatsby-plugin-image"

type SubHeroProps = {
  title: string
  buttonText: string
  buttonAlignedText: string
  image: IGatsbyImageData
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const SubHeroView = (props: SubHeroProps) => {
  const { image, title, buttonText, buttonAlignedText, onClick } = props

  return (
    <Container>
      <GatsbyImage image={image} alt={title} />
      <MetaContainer>
        <PageTitle>{title}</PageTitle>
        <ButtonTextContainer>
          <PrimaryButton onClick={onClick}>{buttonText}</PrimaryButton>
          <h4>{buttonAlignedText}</h4>
        </ButtonTextContainer>
      </MetaContainer>
    </Container>
  )
}

export default SubHeroView

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: center;

  & img {
    border-radius: 10px;
  }
`

const MetaContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`

const ButtonTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  align-items: center;
`
