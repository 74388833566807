import React from "react"

import { IGatsbyImageData } from "../../node_modules/gatsby-plugin-image"
import styled from "@emotion/styled"
import { GatsbyImage } from "gatsby-plugin-image"

type Props = {
  screenshots: IGatsbyImageData[]
}

const StaggeredScreenshotComponent = (props: Props) => {
  return (
    <Container>
      {props.screenshots.map((screenshot: IGatsbyImageData) => {
        return (
          <ItemContainer>
            <GatsbyImage
              alt={"This is a temporary image alt"}
              image={screenshot}
            />
          </ItemContainer>
        )
      })}
    </Container>
  )
}

export default StaggeredScreenshotComponent

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

const ItemContainer = styled.div`
  width: 30%;
  &:nth-child(3n - 1) {
    padding-top: 4rem;
  }
`
