import React, { useState } from "react"

import { graphql } from "gatsby"

import { formatPrice } from "../utils/currency-helpers"
import { normalizedProduct } from "../models/product"
import { getStripe } from "../utils/stripejs"

import Layout from "../components/layout"

import SubHeroView from "../components/sub-hero-view"
import TextComponent from "../components/text-component"
import ListView from "../components/list"
import ContactForm from "../components/contact-form"
import StaggeredScreenshotComponent from "../components/staggered-screenshot-component"
import CallToActionComponent from "../components/call-to-action-component"
import SectionHeader from "../components/section-header"

import { MdOutlineContactMail } from "react-icons/md"
import Head from "../components/head"

export const query = graphql`
  query($slug: String!) {
    productsJson(slug: { eq: $slug }) {
      featuredImage {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 250, height: 250)
        }
      }
      screenshotImages {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      description
      features
      name
      slug
    }
    stripePrice(product: { metadata: { slug: { eq: $slug } } }) {
      unit_amount
      id
      product {
        name
      }
      currency
    }
  }
`

const ProductDetail = ({ data: { productsJson, stripePrice } }) => {
  const [loading, setLoading] = useState(false)
  const product = normalizedProduct(productsJson, stripePrice)

  const {
    id,
    name,
    unitAmount,
    currency,
    featuredImage,
    screenshotImages,
    description,
    features,
  } = product

  const formattedPrice = formatPrice(unitAmount, currency)

  return (
    <Layout>
      <Head title={`iOS App Template - ${name}`} />
      {/* Header Section */}
      <section>
        <SubHeroView
          title={name}
          buttonText={"Buy Now"}
          buttonAlignedText={formattedPrice}
          image={product.featuredImage}
          onClick={event => buyClicked(event, id, setLoading)}
        />
      </section>

      {/* Description Section */}
      <section>
        <TextComponent paragraphs={description} />
      </section>

      {/* Screenshots */}
      <section>
        <StaggeredScreenshotComponent screenshots={screenshotImages} />
      </section>

      {/* Features & Benefits List Section */}
      <section>
        <ListView items={features} />
      </section>

      {/* Buy Now Container */}
      <section>
        <CallToActionComponent
          formattedPrice={formattedPrice}
          onClick={event => buyClicked(event, id, setLoading)}
        />
      </section>

      {/* Contact Form */}
      <section>
        <SectionHeader
          topTitle="Want to work together?"
          middleTitle="Drop me a line!"
          bottomTitle="I'll reply within 24 hours"
        >
          <MdOutlineContactMail size={20} />
        </SectionHeader>
        <ContactForm />
      </section>
    </Layout>
  )
}

const buyClicked = async (
  event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  id: string,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  event.preventDefault()
  setLoading(true)

  const stripe = await getStripe()

  if (stripe) {
    const { error } = await stripe.redirectToCheckout({
      mode: "payment",
      lineItems: [{ price: id, quantity: 1 }],
      successUrl: `${window.location.origin}/`,
      cancelUrl: `${window.location.origin}/failure/`,
    })

    if (error) {
      console.warn("Error:", error)
    }

    setLoading(false)
  }
}

export default ProductDetail
