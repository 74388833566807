import React from "react"
import styled from "../../node_modules/@emotion/styled"

type TextProps = {
  paragraphs: string[]
}

const TextComponent = (props: TextProps) => {
  return (
    <Container>
      {props.paragraphs.map((s: string) => {
        return <Paragraph>{s}</Paragraph>
      })}
    </Container>
  )
}

export default TextComponent

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`

const Paragraph = styled.p`
  text-align: justify;
`
