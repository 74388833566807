import React from "react"
import styled from "../../node_modules/@emotion/styled"
import TextComponent from "./text-component"

import { StaticImage } from "gatsby-plugin-image"

type ListViewProps = {
  items: string[]
}

const staticImageProps = {
  alt: "Check mark icon",
  placeholder: "blurred",
  width: 35,
  height: 35,
}

const ListView = (props: ListViewProps) => {
  return (
    <Container>
      {props.items.map((item: string) => {
        return (
          <div>
            <Spacer />
            <ItemContainer>
              <StaticImage
                src={"../assets/images/check-icon.png"}
                {...staticImageProps}
              />
              <TextComponent paragraphs={[item]} />
            </ItemContainer>
          </div>
        )
      })}
    </Container>
  )
}

export default ListView

export const Container = styled.div`
  border-radius: 20px;
  background-color: var(--bgOffset);
  padding: 2rem;
  padding-top: 0;
`

export const Spacer = styled.div`
  height: 2rem;
`

export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`
