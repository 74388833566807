import React from "react"
import styled from "@emotion/styled"

import TextComponent from "../components/text-component"

type Props = {
  formattedPrice: string
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const CallToActionComponent = (props: Props) => {
  return (
    <Container>
      <InnerContainer>
        <TextComponent paragraphs={text} />
        <BuyNowButton onClick={props.onClick}>
          <h3>Buy Now!</h3>
          <p>{`For only ${props.formattedPrice}`}</p>
        </BuyNowButton>
      </InnerContainer>
    </Container>
  )
}

export default CallToActionComponent

const text: string[] = [
  "Build your dream application today with our production quality app kit, and shave weeks off development time and cost. Buy now with a one-off hassle free payment.",
]

const Container = styled.div`
  background: rgb(255, 0, 90);
  background: linear-gradient(
    90deg,
    rgba(255, 0, 90, 1) 0%,
    rgba(255, 0, 90, 1) 45%,
    rgba(255, 0, 90, 0) 75%
  );
  padding: 2rem;
  border-radius: 10px;
`

const InnerContainer = styled.div`
  display: flex;
  gap: 5rem;
  align-items: center;
`

const BuyNowButton = styled.button`
  width: 40%;
  transition: transform 0.3s;
  padding: 1rem;
  border-style: none;
  background-color: var(--bgOffset);
  border-radius: 5px;
  color: var(--foreground);
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-direction: column;

  & h3 {
    font-size: 1rem;
  }

  & p {
    font-size: 0.6rem;
    line-height: 0.8rem;
  }

  &:hover {
    cursor: pointer;
    transform: scale(1.05);
  }
`
